import React from 'react';
import profile from "../assets/profile.jpg"
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function About(props) {
    return (
        <div>
            <div className='flex max-[450px]:flex-col max-[450px]:gap-y-5 max-[450px]:text-center max-[450px]:h-screen justify-center items-center gap-x-10 h-[40rem]'>
                <div>
                    <img src={profile} loading='lazy' alt="" className='rounded-full shadow-lg hover:scale-105 hover:shadow-xl transition-all ease-in duration-200 overflow-hidden w-[15rem]' />
                </div>
                <div className='w-[20%] max-[450px]:w-[90%]'>
                    <span className='text-xl'>
                        Hi, Iam Anubhav Ghosh currently persuing my B.Tech in computer science
                        If you like this project please give a follow on linkedin and github.
                    </span>
                </div>
            </div>
            <div className='flex justify-center gap-x-10 text-[2.9rem] mb-10'>
                <a href="https://github.com/Anubhav-Ghosh1" target='_blank'>
                    <FaGithub className='hover:scale-105 transition-all ease-in duration-200' />
                </a>
                <a href="https://www.linkedin.com/in/anubhav-ghosh11/" target='_blank'>
                    <FaLinkedin className='text-blue-600 hover:scale-105 transition-all ease-in duration-200' />
                </a>
                <a href="https://www.youtube.com/@AnubhavGhosh03" target='_blank'>
                    <FaYoutube className='text-red-600 hover:scale-105 transition-all ease-in duration-200' />
                </a>
                <a href="https://www.instagram.com/guywhocodess" target='_blank'>
                    <FaInstagram className='text-pink-600 hover:scale-105 transition-all ease-in duration-200' />
                </a>
            </div>
        </div>
    );
}

export default About;