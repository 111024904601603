import { useState } from "react";
import "./App.css";
import JsonFormatter from "./components/JsonFormatter";
import NavBar from "./components/NavBar";
import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  return (
   <div>
    <NavBar/>
    <Routes>
      <Route path="/about" element={<About/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/" element={<JsonFormatter/>}/>
    </Routes>
   
   </div>
  );
}

export default App;
