import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { FaCopy } from "react-icons/fa";

function JsonFormatter(props) {

    const [inputJson,setInputJson] = useState("");
    const [outputJson,setOuputJson] = useState("");
    const [space,setSpace] = useState(4);
    const navigate = useNavigate();
    const scrollToRef = useRef(null);

    // useEffect(()=>{
        // console.log(inputJson)
    // },[inputJson])

    function convert()
    {
        try
        {
            const value = JSON.stringify(JSON.parse(inputJson),null,parseInt(space));
            // console.log(space)
            // console.log(value);
            toast.success("Successfully formatted JSON")
            handleClick()
            setOuputJson(value);
        }
        catch(e)
        {
            toast.error("Invalid JSON")
        }
    }

    function copyText()
    {
        if(outputJson === "")
        {
            toast.error("Output is empty");
            return;
        }
        navigator.clipboard.writeText(outputJson).then(()=>{
            toast.success("Formatted JSON Copied")
        }).catch((e)=>{
            toast.error("Error while copying to clipboard")
        });
    }

    const handleClick = () => {
        // Scroll to the referenced element
        scrollToRef.current.scrollIntoView({ behavior: "smooth" });
      };

      const scroll = () => {
      }

    return (
        <div className='bg-[#f8f8f8]'>
            {/* 
            <button onClick={()=>{
                convert()
            }}>Convert</button>
             */}
            <div className='bg-[#297fb8] flex flex-col justify-center'>
                <div className='flex max-[450px]:flex-col w-full justify-center'>
                    <div className='w-[50%] max-[450px]:w-[90%] m-10 flex flex-col'>
                        <span className='text-white text-lg font-semibold'>JSON Data</span>
                        <div>
                            <textarea name="" id="" className='w-[90%] resize-none outline-none rounded-md p-4 placeholder:text-lg placeholder:text-black placeholder:opacity-80' rows="15" onChange={(e) => {
                                setInputJson(e.target.value)
                            }} placeholder='{"Enter Json here": "💻"}'>
                            </textarea>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <span className='text-white text-lg font-semibold'>JSON Template</span> 
                        <select onChange={(e)=>{
                            setSpace(e.target.value)
                        }} name="" id="" className='bg-gray-50 max-[450px]:w-[40%] max-[450px]:mb-5 border outline-none border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                            <option value="4">4 Spaces</option>
                            <option value="3">3 Spaces</option>
                            <option value="2">2 Spaces</option>
                            <option value="1">1 Spaces</option>
                        </select>
                    </div>
                </div>
                <div className='flex mb-10 justify-center items-center '>
                    <button onClick={()=>{
                        convert()
                    }} className='bg-[#4ab4fb] hover:bg-[#2e9be4] hover:shadow-lg transition-all ease-in duration-200 text-white text-xl font-semibold px-4 py-4 rounded-md'>
                        Process
                    </button>
                </div>
            </div>
            <div className='mt-20 bg-[#222222]'>
                <div ref={scrollToRef} className='flex flex-col justify-center items-center m-5 md:m-20 mb-0 md:p-20 p-5'>
                    <div className='flex justify-between w-[65%]'>
                        <span className='text-left text-white text-xl font-semibold mb-2'>JSON Output</span>
                        <FaCopy onClick={()=>{
                            copyText()
                        }} className='text-xl text-white hover:text-[#e9e9e9] transition-all ease-in duration-200'/>
                    </div>
                    <textarea name="" id="output" rows="15" className='w-[100%] md:w-[70%] resize-none outline-none rounded-md p-4 placeholder:text-lg placeholder:text-black placeholder:opacity-80' value={outputJson} placeholder='{"Json will be visible here":"💻"}' readOnly></textarea>
                </div>
            </div>
        </div>
    );
}

export default JsonFormatter;