import React from "react";
import { Link } from "react-router-dom";

function NavBar(props) {
  return (
    <div className="bg-[#f8f8f8] flex justify-center items-center">
      <div className="flex items-center justify-between md:w-[70%] w-[90%] h-[4rem]">
        <Link to={"/"}>
            <div className="flex items-end gap-x-2">
                <span className="text-[#297fb8] font-bold text-4xl">JSON</span>
                <span className="text-xl font-semibold">Formatter</span>
            </div>
        </Link>
        <div>
          <ul className="flex justify-center items-center gap-x-4">
            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
