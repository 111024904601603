import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact(props) {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_7iyfjgy', 'template_vwp2129', form.current, {
        publicKey: 'XT20oPh8ILKspy_EO',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    }
  return (
    <div className="flex flex-col h-screen justify-center items-center ">
        <span className="text-2xl font-semibold mb-5">Contact Us</span>
      <div className="bg-[#297fb8] w-[70%] rounded-md">
        <form ref={form} onSubmit={sendEmail} className="flex p-10 flex-col">
            <label className="text-white text-lg font-semibold">Name</label>
            <input type="text" name="user_name" className="rounded-md h-10" />
            <label className="text-white text-lg font-semibold mt-4">Email</label>
            <input type="email" name="user_email" className="rounded-md h-10" />
            <label className="text-white text-lg font-semibold mt-4">Message</label>
            <textarea name="message" className="rounded-md h-10" />
            <input className="bg-[#4ab4fb] flex justify-center  items-center mt-5 hover:bg-[#2e9be4] hover:shadow-lg transition-all ease-in duration-200 text-white text-xl font-semibold px-4 py-4 rounded-md" type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
}

export default Contact;
